.home-contaier {
    /* background-color: black; */
    min-height: calc(100vh);
    width: 86%;
    
    padding: 53px 0px 53px 0px;
    padding-left: 7%;
    z-index: 200;
    /* -webkit-overflow-scrolling:inherit */

}
.homemaincon {
    background-color: black;
    min-height: 100vh !important;
}
.grid-area {
    color: #171717 !important;
}
.home-logo-img {
    width: 238px;
    height: 53px;
}

.future-text {
    font-size: 58px;
    font-weight: 700;
    color: white;
    position: absolute;
    bottom: 52px;
    width: calc(100% - 240px) ;
}


.future-text p {
    margin: 0px;
    padding: 0px;
    font-family: 'TrapRegular';
}

.home-join-container {
    display: flex;
    margin-top:48px;
    justify-content: space-between;
    align-items: center;
    width: 100%;

}
.home-join-container-text {
    display: flex;
    /* margin-top:48px; */
    align-items: flex-end;
}

.home-join-text {
 font-size: 24px;

}

.home-see-all-text {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 16px;
    margin-right: 18px;
    color: #00D3E2;
    text-decoration-line: underline;
    margin-bottom: 5px;
    cursor: pointer;
}
.social-icons-container {
    display: flex;
    margin-top: 16px;
    margin-left: -10px !important;

}
.social-icon {
    cursor: pointer;
    color: #454A5F;
}

.social-icon:hover {
    color: #aaa
}



body{
    /* overflow: hidden; */
    background: #000;
}
canvas{
    position: absolute;
    top: 0;
    left: 0;
}

@media (max-width:641px) { /* big landscape tablets, laptops, and desktops */

    .future-text { 
        font-size: 24px;
    }
    .home-join-container {
        display:block;
    }
    .home-join-container-text {
        flex-direction: column;
        align-items: flex-start;

    }
    .home-see-all-text {
        margin-left: 0px;
    }
}