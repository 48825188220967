
.joinus-contaier {
    /* background-color: black; */
    min-height: calc(100vh - 106px);
    width: 86% ;
    /* padding: 53px 125px 53px 125px; */
    padding: 53px 0px 53px 0px;
    padding-left: 7%;
}

.joinus-text {
    color: white;
    margin-top: 70px;
    flex: 1;
}
.joinus-text p {
    font-size: 58px;
    color: white;
    margin: 0px;
    padding: 0px;
    font-family: 'TrapRegular';
    font-weight: 700;

}
.openings-container {
    display: flex;
}
.openings-list-container {
    flex:1
}
.current-opening-text {
    color: white;
    font-family: 'TrapRegular';
    font-size: 24px;
    font-weight: 700;
}
.single-opening {

}
.single-opening p {
    color: white;
    margin: 0px;
    
}
.single-opening-container {
    border: 1px solid #454A5F;
    margin-bottom: 16px;
    /* padding: 16px; */
}
.experiance-text {
    color: #454A5F !important;
    font-family: 'TrapRegular';
    margin-top: 8px !important;

}
.opening-title-text {
 font-size: 24px !important;
 color: white;
}

.accordion-container {
    background-color: black !important; 
}

.accordion-summary {

}

.opening-content {
    color: white;
}
.apply-btn-main {
    display: flex;

}
.flex-1 {
    flex: 1;
}

.apply-btn-text {
    color: white;
}
.apply-btn {
    height: 50px;
    width: 122px;
    background-color: #00D3E2;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 24px;
    cursor: pointer;

}
.location-container {
    display: flex;
    position: fixed;
    bottom: 56px;
}
.location-title {
    color: white;
    margin: 0px;
    font-size: 24px;
    font-weight: 700;
    font-family: 'TrapRegular';
    margin-top: 2px;
}
.location-address-container {
    margin-left: 8px;
}
.mt-48 {
    margin-top: 48px !important;
}
.location-line {
    color: white;
    margin: 0px;
    margin-top: 6px;
}

.content-text {
    color: white;
    font-family: 'TrapRegular';
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
}