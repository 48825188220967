.vertical-line {
    height: 1px;
    width: 100%;
    background-color: #171717;
    margin-top: 31px;
}

.grid-container {
    position:absolute;
    top:0px;
    left: 0px;
    width: 100%;
    z-index: -1;
    background-color: black;
}

.horizontal-line {
    width: 1px;
    height: 100vh;
    background-color: #171717;
    margin-left: 31px;
}
.horizontal-line-container {
    display: flex;
    position: absolute;
    top:0px;
}
.signle-logo-box {
    width: 29px;
    height: 29px;
    background-color: #00D3E2CC;
    /* trans */
    transition: transform 750ms;
    will-change: transform;
}
.signle-logo-box:hover,
  .signle-logo-box:focus {
    transform: translateY(-10px);
  }


.logo-box-container {
    position: absolute;
    top: 91px; 
    /* right: 720px; */
    left: 720px;

}
.coming-zoon-badge {
    width: 123px;
    height: 123px;
    border-radius: 100px;
    background: rgba(255, 255, 255, 0.3);
backdrop-filter: blur(37px);
/* Note: backdrop-filter has minimal browser support */

transform: rotate(120deg);
display: flex;
align-items: center;
justify-content: center;
position: absolute;
left: 440px;

}
.fly-logo{
    width: 600px;
    height: 400px;
    top:60px;

display: flex;
align-items: center;
justify-content: center;
position: absolute;

}
.fly-logo img{
    width: 800px;
    height: 400px;

}
@keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
.coming-zoon-badge img {
    width: 102px;
    height: 102px;
    animation: rotate 10s linear infinite;
transform-origin: center center;
}
@media (max-width:320px)  { /* smartphones, iPhone, portrait 480x320 phones */ 
    
}
@media (max-width:481px)  { /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */ 
   
}
@media (max-width:641px)  { /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */
   
}
@media (max-width:961px)  { /* tablet, landscape iPad, lo-res laptops ands desktops */
   
}
@media (max-width:1025px) { /* big landscape tablets, laptops, and desktops */

    .logo-box-container { 
        display: none;
    }
}
@media (min-width:1281px) { /* hi-res laptops and desktops */

}